const newLoadForm = document.getElementById('js-new-load-form') as HTMLFormElement | null

const latField = document.getElementById('latitude') as HTMLInputElement | null
const lonField = document.getElementById('longitude') as HTMLInputElement | null

const submittingClass = 'js-submitting'

const createLoad = () => {
  if (newLoadForm && !newLoadForm.classList.contains(submittingClass)) {
    newLoadForm.classList.add(submittingClass)
    newLoadForm.submit()
  }
}

const refreshLocationLink = document.getElementById('js-refresh-location')
refreshLocationLink && refreshLocationLink.addEventListener('click', event => {
  event.preventDefault()
  const form = document.getElementById('current-location') as HTMLFormElement | null
  form && form.submit()
})

if (latField && lonField && navigator.geolocation) {
  setTimeout(createLoad, 16000);
  navigator.geolocation.watchPosition(
    (position) => {
      latField.value = String(position.coords.latitude)
      lonField.value = String(position.coords.longitude)

      if (position.coords.accuracy < 15) {
        createLoad()
      }
    },
    (err) => {
      console.error(err)
      createLoad()
    },
    {
      enableHighAccuracy: true,
      timeout: 15000
    }
  );
}
