document.querySelectorAll<HTMLAnchorElement>('.js-load-note').forEach(link => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    // Load ID data attribute is present on the load list, where there is a namespaced form for each load
    const loadId = link.getAttribute('data-load-id')
    // On the load list, use the load id to get the namespaced input id. Otherwise use rails generated id
    const noteInputId = loadId ? `load_${loadId}_load_note` : 'load_note'
    const noteInput = document.getElementById(noteInputId) as HTMLInputElement
    const newNote = window.prompt('Enter Note for Load:', noteInput ? noteInput.value : '')

    if (typeof newNote === 'string') {
      noteInput.value = newNote

      if (loadId) {
        const loadForm = document.getElementById(`load_${loadId}_edit_load_${loadId}`) as HTMLFormElement
        loadForm.submit()
      } else {
        // This isn't doing form submission, so change the link text manually
        link.text = `${newNote.length ? 'Edit' : 'Add'} Note`
      }
    }
  })
})