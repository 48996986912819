const noTicketLink = document.getElementById('js-switch-weigh-field') as HTMLAnchorElement

const toWeightText = "Don't have a ticket number?"
const toTicketNumberText = "Have a ticket number to enter?"

if (noTicketLink) {
  const ticketInput = document.getElementById('load_ticket_number') as HTMLInputElement
  const weightInput = document.getElementById('load_gross_weight_lbs') as HTMLInputElement
  const ticketField = document.getElementById('ticket-field') as HTMLDivElement
  const weightField = document.getElementById('weight-field') as HTMLDivElement

  noTicketLink.addEventListener('click', event => {
    event.preventDefault()

    // clear both values
    ticketInput.value = ''
    weightInput.value = ''

    // toggle hidden and required
    ticketField.classList.toggle('hidden')
    ticketInput.toggleAttribute('required')

    weightField.classList.toggle('hidden')
    weightInput.toggleAttribute('required')

    // switch link text based on active field
    if (weightField.classList.contains('hidden')) {
      noTicketLink.text = toWeightText
    } else {
      noTicketLink.text = toTicketNumberText
    }
  })
}