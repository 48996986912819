
const menuButton = document.getElementById('js-menu-button') as HTMLButtonElement
const menuOverlay = document.getElementById('header-menu') as HTMLDivElement

const toggleMenu = () => menuOverlay.classList.toggle('open')

if (menuButton && menuOverlay) {
  menuButton.addEventListener('click', toggleMenu)
  menuOverlay.addEventListener('click', toggleMenu)
}
